<template>
  <b-card v-if="requestHistories">
    <b-card-title style="font-size: xx-large; text-align: center;">
      Request Information
    </b-card-title>
    <b-card-text
      v-if="requestHistories.request_code"
    ><strong>Request Code: </strong>{{ requestHistories.request_code }}</b-card-text>
    <b-card-text
      v-if="requestHistories.payment_status"
    ><strong>Payment Status: </strong>{{ requestHistories.payment_status }}</b-card-text>
    <b-card-text
      v-if="requestHistories.due_date"
    ><strong>Due Date: </strong>{{ requestHistories.due_date }}</b-card-text>
    <b-card-text
      v-if="requestHistories.source"
    ><strong>Sourcee: </strong>{{ requestHistories.source }}</b-card-text>
    <b-card-text
      v-if="requestHistories.certificate"
    ><strong>Certificate: </strong>{{ requestHistories.certificate }}</b-card-text>
    <b-card-text
      v-if="requestHistories.final_price"
    ><strong>Final Price: </strong>{{ requestHistories.final_price }}</b-card-text>

    <div style="display: flex; flex-wrap: wrap; gap: 10px;">
      <div
        style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Office
        </b-card-title>
        <b-card-text
          v-if="requestHistories.office"
        ><strong>Office Name: </strong>{{ requestHistories.office.name }}</b-card-text>
        <b-card-text
          v-if="requestHistories.office"
        ><strong>Office Country: </strong>{{ requestHistories.office.country }}</b-card-text>
        <b-card-title style="font-size: xx-large;">
          Request offer
        </b-card-title>
        <b-card-text
          v-if="requestHistories.offer"
        ><strong>offer: </strong>{{ requestHistories.offer.name }}</b-card-text>
        <b-card-text
          v-if="requestHistories.offer"
        ><strong>Office amount: </strong>{{ requestHistories.offer.amount }}</b-card-text>
      </div>
      <div
        style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Facility
        </b-card-title>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Name AR: </strong>{{ requestHistories.facility.name.ar }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Name EN: </strong>{{ requestHistories.facility.name.en }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>CR End Date: </strong>{{ requestHistories.facility.CR_num }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>CR Num: </strong>{{ requestHistories.facility.CR_end_date }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Phone: </strong>{{ requestHistories.facility.phone }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Activity EN: </strong>{{ requestHistories.facility.activity.en }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Activity AR: </strong>{{ requestHistories.facility.activity.ar }}</b-card-text>

        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Address: </strong>{{ requestHistories.facility.address }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Postal Code: </strong>{{ requestHistories.facility.postal_code }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Zip Code: </strong>{{ requestHistories.facility.zip_code }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Audit License Expiration: </strong>{{ requestHistories.facility.audit_license_expiration }}</b-card-text>
        <b-card-text
          v-if="requestHistories.facility"
        ><strong>Survialnce: </strong>{{ requestHistories.facility.survialnce }}</b-card-text>
      </div>
      <div
        style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
      >
        <b-card-title style="font-size: xx-large;">
          Delegator
        </b-card-title>
        <b-card-text
          v-if="requestHistories.delegator"
        ><strong>Name: </strong>{{ requestHistories.delegator.name }}</b-card-text>
        <b-card-text
          v-if="requestHistories.delegator"
        ><strong>Country: </strong>{{ requestHistories.delegator.email }}</b-card-text>
        <b-card-text
          v-if="requestHistories.delegator"
        ><strong>Phone: </strong>{{ requestHistories.delegator.phone }}</b-card-text>
        <b-card-text
          v-if="requestHistories.delegator"
        ><strong>Wallet Balance: </strong>{{ requestHistories.delegator.wallet_balance }}</b-card-text>
        <b-card-text
          v-if="requestHistories.delegator"
        ><strong>Auto Proceed: </strong>
          {{
            requestHistories.delegator.auto_proceed === 1 ? "true" : "false"
          }}</b-card-text>

        <b-card-text
          v-if="requestHistories.delegator"
        ><strong>Delegator Offer: </strong>{{ requestHistories.delegator.offer.name }} -
          {{ requestHistories.delegator.offer.amount }} %
        </b-card-text>
      </div>
    </div>
    <b-card-title style="font-size: xx-large; padding: 10px;">
      Assigned To
    </b-card-title>
    <div style="display: flex; flex-wrap: wrap; gap: 10px;">
      <div
        v-for="assigned in requestHistories.assigned_to"
        :key="assigned.id"
        style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
      >
        <b-card-text><strong>Name: </strong>{{ assigned.name }}</b-card-text>
        <b-card-text><strong>Email : </strong>{{ assigned.email }}</b-card-text>
        <b-card-text><strong>Phone: </strong>{{ assigned.phone }}</b-card-text>
        <b-card-text><strong>Job: </strong>{{ assigned.job }}</b-card-text>
      </div>
    </div>
  </b-card>
</template>
<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      loadCard: false,
      requestHistories: [],
    }
  },

  mounted() {
    this.showRequestHistories()
  },
  methods: {
    showRequestHistories() {
      this.loadCard = true
      axios
        .get(`requests/${this.$route.params.id}/short-info`)
        .then(res => {
          this.requestHistories = decryptData(res.data.payload).data.request
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loadCard = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.card-header {
  font-size: 38px;
  font-weight: 800;
}
</style>
